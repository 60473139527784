import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLicentaContext } from "./context/licentaContext";
import moment, { relativeTimeRounding } from "moment";
import { useCuiContext } from "./context/cuiContext";
import { firestore, auth } from "./firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Button, Modal, Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import Spinner from "../common/Spinner/Spinner";
import { useLicentaDataContext } from "./context/licentaDataContext";
import { useLicentaContextGlobal } from "./context/licentaContextGlobal";
import { useUserContext } from "./context/userContext";

const LicenteContainer = ({
  nume,
  valoare,
  appId,
  existsInFirestore,
  onCreate,
  onClick,
  onCreateLicenta,
  data,
  setTrigger,
  hwid,
  deviceName,
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalL, setOpenConfirmModalL] = useState(false);
  const { user } = useUserContext();

  const { setLicentaDataVal } = useLicentaDataContext();
  const [isSelected, setIsSelected] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [existsInLicentiere, setExistsInLicentiere] = useState(false);

  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const handleOpenConfirmModalL = () => setOpenConfirmModalL(true);
  const handleCloseConfirmModalL = () => setOpenConfirmModalL(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmCreate = () => {
    onCreate();
    enqueueSnackbar(`${nume} a fost creata in cloud.`, {
      variant: "success",
    });
    handleCloseConfirmModal();
    setTrigger(currentTime);
  };

  const handleCreateLicenta = () => {
    onCreateLicenta();
    handleCloseConfirmModalL();
    setTrigger(currentTime);
  };

  const existaLicentetieereHwid = data.filter((item) => item.appId != appId);

  const existsLicentiereArrayFinal = existaLicentetieereHwid.filter(
    (item) => item.hwid == `CLOUD-${nume}`
  );

  const licentaCloud = data.filter((item) => item.appId == 851370);

  const licentaCloudFinal = licentaCloud.filter(
    (item) => item.hwid == `CLOUD-${nume}`
  );

  console.log("licCloud", licentaCloud);

  useEffect(() => {
    if (existsLicentiereArrayFinal[0]) {
      setExistsInLicentiere(true);
    }
  }, []);

  const handleAppId = (appId) => {
    switch (appId) {
      case "456":
        return <span>Licență BC50</span>;

      case "652":
        return <span>Licență Android</span>;

      case "987":
        return <span>Licență Self-Checkout</span>;

      case "851370":
        return <span>Licență Cloud</span>;
    }
  };

  const currentTime = Date.now();

  // Get the cloud license expiration date from `licentaCloudFinal`
  const cloudLicenseExpireTime = licentaCloudFinal[0]?.expire * 1000; // Convert from seconds to milliseconds

  // UNDO ME HERE
  // const isExpired = currentTime > cloudLicenseExpireTime;
  const isExpired = null;

  const timeLicense = licentaCloudFinal[0]?.expire * 1000;

  console.log(nume, currentTime, timeLicense);

  let str = "HelloWorld";

  function makeTextSmaller(str) {
    // Get the first 2 characters
    let firstTwo = str.slice(0, 2); // "He"

    // Get the last 3 characters
    let lastThree = str.slice(-4); // "rld"

    // Concatenate or display them as needed
    let result = firstTwo + "..." + lastThree; // "Herld"

    return result;
  }

  const [loading, setLoading] = useState(false);

  const prelungesteLicenta = async (time) => {
    setLoading(true);
    try {
      let baseUrl = "https://sandbox.licente.hellofuture.ro/api/v2/paymentlink";
      const key = "ce469badb142f5d3866566c394260a14";
      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          key: key,
          HWID: `CLOUD-${nume}`,
          new_type: time,
          "extra[test_license]": 1,
        }).toString(),
      });
      const json = await response.json();
      setLoading(false);
      if (json.status === "error") {
        alert(json.msg);
      } else if (json.status === "ok") {
        window.open(json.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      alert("Eroare conexiune cu platforma de licentiere.");
    }
  };

  const [openConfirmPrelungireModal, setOpenConfirmPrelungireModal] =
    useState(false);

  const [shouldResetPosition, setShouldResetPosition] = useState(false);

  // hover effect transition
  const handleMouseEnter = () => {
    setIsSelected(true);
    setShouldResetPosition(false);
  };

  const handleMouseLeave = () => {
    setIsSelected(false);
    setTimeout(() => {
      setShouldResetPosition(true);
    }, 200);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "20px",
          paddingLeft: "10px",
        }}
      >
        <div style={{ position: "relative", marginBottom: "5px" }}>
          <div
            className={`licenta-box ${isSelected ? "hovered" : ""} ${
              isClicked ? "clicked" : ""
            }`}
            style={{
              width: "300px",
              height: "160px",
              border: `1px solid ${existsInFirestore ? "#0080ff" : "#000030"}`,
              borderRadius: "16px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              cursor: isExpired
                ? "not-allowed"
                : existsInFirestore
                ? "pointer"
                : "default", // Disable click for expired Cloud license
              backgroundColor: isExpired ? "#f0f0f0" : "#F7F9FB", //change background color if expired
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "'Helvetica Neue', Arial, sans-serif",
              color: "#00054e",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
              transform: isSelected ? "scale(1.05)" : "scale(1)",
              boxShadow: isSelected
                ? "0 4px 12px rgba(0, 128, 255, 0.5)"
                : "0 4px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 50,
            }}
            onClick={!isExpired && existsInFirestore ? onClick : null} // Disable click if expired
            // onMouseEnter={() => setIsSelected(true)}
            // onMouseLeave={() => setIsSelected(false)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "20px",
                width: "100%",
              }}
            >
              <div>
                <span style={{ fontSize: 20, fontWeight: 500 }}>
                  {handleAppId(appId)}
                </span>{" "}
                - <span>{deviceName}</span>
                {/* - <span>{makeTextSmaller(hwid)}</span> */}
              </div>
              <br />
              <span>{nume}</span>
              <span style={{ paddingTop: "30px" }}>
                Expiră: {moment(valoare * 1000).format("DD.MM.YYYY")}
              </span>
            </div>
          </div>

          {existsInLicentiere && (
            <>
              <svg
                // height="20"
                height={shouldResetPosition ? "20" : "17"}
                // height={isSelected ? "17" : "20"} // Adjust the height based on isSelected hover effect
                width="300" // Adjust the width as needed
                style={{
                  position: "absolute",
                  // top: isSelected ? "162.5px" : "161px",
                  top: shouldResetPosition ? "160px" : "162.5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 0,
                }}
              >
                <line
                  x1="50%"
                  y1="0"
                  x2="50%"
                  y2="40"
                  style={{ stroke: "black", strokeWidth: 2, zIndex: 0 }}
                />
              </svg>
              <div
                style={{
                  width: "300px",
                  height: "160px",
                  border: "1px solid #0080ff",
                  borderRadius: "16px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#F7F9FB",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "'Helvetica Neue', Arial, sans-serif",
                  color: "#00054e",
                  marginTop: "20px", // Adjust as needed for spacing
                  zIndex: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  {existsInFirestore ? (
                    <>
                      <h4>
                        Licență cloud{" "}
                        {currentTime < licentaCloudFinal[0]?.expire * 1000
                          ? "activă"
                          : "expirată"}
                      </h4>
                      Expiră:{" "}
                      {moment(licentaCloudFinal[0]?.expire * 1000).format(
                        "DD.MM.YYYY"
                      )}
                      <br />
                      <br />
                      <Button
                        onClick={() => setOpenConfirmPrelungireModal(true)}
                        variant="contained"
                        color="primary"
                      >
                        Prelungește licența
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleOpenConfirmModal}
                        variant="contained"
                        color="primary"
                      >
                        Creeaza licenta cloud
                      </Button>
                      <Modal
                        open={openConfirmModal}
                        onClose={handleCloseConfirmModal}
                        aria-labelledby="confirm-modal-title"
                        aria-describedby="confirm-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            border: "2px solid #000",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "12px",
                          }}
                        >
                          <Typography
                            id="confirm-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Confirma licenta cloud
                          </Typography>
                          <Typography
                            id="confirm-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Esti sigur ca vrei sa adaugi licenta{" "}
                            <strong>{nume}</strong> in cloud?
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 2,
                            }}
                          >
                            <Button
                              onClick={handleCloseConfirmModal}
                              variant="contained"
                              color="secondary"
                              sx={{ mr: 1 }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleConfirmCreate}
                              variant="contained"
                              color="primary"
                            >
                              Continue
                            </Button>
                          </Box>
                        </Box>
                      </Modal>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {!existsInLicentiere && (
            <Button
              onClick={handleOpenConfirmModalL}
              variant="contained"
              color="primary"
            >
              Creeaza instanta cloud
            </Button>
          )}
        </div>
      </div>

      <Modal
        open={openConfirmModalL}
        onClose={handleCloseConfirmModalL}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "12px",
          }}
        >
          <Typography id="confirm-modal-title" variant="h6" component="h2">
            Confirma in licentiere
          </Typography>
          <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
            Esti sigur ca vrei sa adaugi licenta <strong>{nume}</strong> in
            licentiere?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={handleCloseConfirmModalL}
              variant="contained"
              color="secondary"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateLicenta}
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openConfirmPrelungireModal}
        onClose={() => setOpenConfirmPrelungireModal(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "12px",
          }}
        >
          <Typography id="confirm-modal-title" variant="h6" component="h2">
            Doriti sa prelungiti licenta de cloud?
          </Typography>
          <Typography id="confirm-modal-title" variant="h6" component="h2">
            Pentru - {nume}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              gap: 12,
              marginTop: 12,
            }}
          >
            <Button
              onClick={() => prelungesteLicenta(1)}
              variant="contained"
              color="primary"
            >
              Un An
            </Button>

            <Button
              onClick={() => prelungesteLicenta(30)}
              variant="contained"
              color="primary"
            >
              O Luna
            </Button>
          </div>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={() => setOpenConfirmPrelungireModal(false)}
              variant="contained"
              color="secondary"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const AiPosView = ({ licenteArray }) => {
  const [licente, setLicenta] = useState([]);
  const [match, setMatch] = useState([]);
  const { cuis, setCuis } = useCuiContext();
  const { setLicentaData } = useLicentaContext();
  const navigate = useNavigate();
  const { setLicentaDataGlobal } = useLicentaContextGlobal();
  const { user } = useUserContext();

  const { setLicentaDataVal } = useLicentaDataContext();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [trigger, setTrigger] = useState();

  const [hwidI, setHwidI] = useState();
  const [cuiI, setCuiI] = useState();

  const refactoredLicente = licenteArray.map((item) => ({
    nume: item?.nume,
    valoare: item?.vanzari?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.value,
      0
    ),
    id: item.id,
  }));

  const totalValue = refactoredLicente.reduce(
    (accumulator, item) => accumulator + item.valoare,
    0
  );

  const fetchData = async (cui) => {
    const key = "ce469badb142f5d3866566c394260a14";

    try {
      const response = await fetch(
        "https://sandbox.licente.hellofuture.ro/api/v2/cui_licenses",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            key: key,
            cui: cui,
          }).toString(),
        }
      );

      const res = await response.json();
      console.log("Fetched licenses:", res.licenses);

      const filteredArray = res.licenses.map((license) => ({
        id: license.id,
        appId: license.appid,
        serial: license.serial,
        expire: license.expire,
        hwid: license.HWID,
        deviceName: license.DeviceName,
      }));
      setData(filteredArray);

      return filteredArray;
    } catch (error) {
      console.error("Error fetching licenses from API:", error);
      return [];
    }
  };

  // ultima licenta a o bage in data array local

  const checkLicenseInFirestore = async (cui, licenseId) => {
    try {
      const licenseDocRef = doc(firestore, `${cui}`, `${licenseId}`);
      const licenseDocSnap = await getDoc(licenseDocRef);
      return licenseDocSnap.exists();
    } catch (error) {
      console.error("Error checking license in Firestore:", error);
      return false;
    }
  };

  const createLicenseInFirestore = async (cui, license) => {
    try {
      const licenseDocRef = doc(firestore, `${cui}`, `${license.serial}`);
      await setDoc(licenseDocRef, {
        serial: license.serial,
        expire: license.expire,
        products: [],
        transactions: [],
        updates: [],
        nomenclatoare: {
          categories: [],
          sgrs: [],
          tvas: [],
          ums: [],
        },
        businessInfo: {},
      });
      // console.log(`License ${license.serial} created in Firestore`);
    } catch (error) {
      console.error("Error creating license in Firestore:", error);
    }
  };

  const fetchDateClient = async (hwid) => {
    try {
      let baseUrl = "https://sandbox.licente.hellofuture.ro/api/v2/restore";
      const key = "ce469badb142f5d3866566c394260a14";
      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          key: key,
          "data[HWID]": hwid.hwid,
          "data[all_data]": 0,
          "extra[test_license]": 1,
        }).toString(),
      });
      const json = await response.json();
      return json.data;
    } catch (e) {
      console.error(e);
    }
  };

  const createAccount = async (hwid) => {
    const dateClient = await fetchDateClient(hwid);

    try {
      const key = "ce469badb142f5d3866566c394260a14";
      const response = await fetch(
        "https://sandbox.licente.hellofuture.ro/api/v2/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            key: key,
            "data[HWID]": `CLOUD-${hwid.serial}`, // `CLOUD-${hwid}`,
            "data[DeviceName]": hwid.deviceName,
            "data[CodDistribuitor]": dateClient.CodDistribuitor,
            "data[TipLicenta]": dateClient.TipLicenta,
            "data[CUI]": hwid.cui,
            "data[PlatitorTva]": 1,
            "data[application]": 851370,
            "data[NumeClient]": user.name,
            "data[Tara]": "",
            "data[Judet]": "",
            "data[Localitate]": "",
            "data[Adresa]": "",
            "data[NumePersoana]": user.name,
            "data[Telefon]": dateClient.partner_phone,
            "data[Email]": dateClient.partner_email,
            "extra[test_license]": 1,
          }).toString(),
        }
      );

      const res = await response.json();

      if (res.status == "error") {
        enqueueSnackbar(`${res.message}`, {
          variant: "error",
        });
      }

      if (res.status == "ok") {
        enqueueSnackbar(`${res.serial} a fost creata in licentiere.`, {
          variant: "success",
        });
      }
    } catch (error) {
      alert("", "Eroare conexiune cu platforma de licentiere!");
    }
  };

  const handleCreateLicenseInLicentiere = async (licenta) => {
    await createAccount(licenta);
  };

  const fetchLicenses = async (cuis) => {
    try {
      let allLicenses = [];
      for (const cui of cuis) {
        const apiLicenses = await fetchData(cui);
        for (const license of apiLicenses) {
          const existsInFirestore = await checkLicenseInFirestore(
            cui,
            license.serial
          );
          allLicenses.push({ ...license, cui, existsInFirestore });
        }
      }
      console.log("licente4", allLicenses);

      const justLicenses = allLicenses.filter((item) => item.appId != 851370);

      setLicenta(justLicenses);
      setLicentaDataGlobal(justLicenses);

      console.log("licente5", justLicenses);
      setLoading(true);
    } catch (error) {
      console.error("Error fetching licenses:", error);
    }
  };

  const handleLicenseClick = async (license) => {
    if (license.existsInFirestore) {
      setLicentaData(license);
      //     navigate(`/ai_pos/licenta/${license.serial}`);
      //   }

      //   setLicentaDataVal(license);
      // };
      setTimeout(() => {
        navigate(`/ai_pos/licenta/${license.serial}`);
      }, 300);
    } else {
      setLicentaDataVal(license);
    }
  };

  const fetchCuis = async (userId) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        const cuiArray = userData.CUIs || [];

        setCuis(cuiArray);
        return cuiArray;
      } else {
        console.error("No such user document!");
        return [];
      }
    } catch (error) {
      console.error("Error fetching CUIs:", error);
      return [];
    }
  };

  const handleCreateLicenseInFirestore = async (cui, license) => {
    await createLicenseInFirestore(cui, license);
    setLicenta((prevLicenses) =>
      prevLicenses.map((l) =>
        l.id === license.id && l.cui === cui
          ? { ...l, existsInFirestore: true }
          : l
      )
    );
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log("User authenticated:", user.uid);
        const fetchedCuis = await fetchCuis(user.uid);
        if (fetchedCuis.length > 0) {
          // console.log("CUIs available, fetching licenses...");
          await fetchLicenses(fetchedCuis);
        } else {
          console.error("No CUIs available, cannot fetch licenses.");
        }
      } else {
        console.error("User not authenticated!");
      }
    });

    return () => unsubscribe();
  }, [setCuis, trigger]);

  // useEffect(() => {
  //   fetchData();
  // }, [handleCreateLicenseInLicentiere]);

  console.log("licenta", licente);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          flexWrap: "wrap",
          minWidth: "min-content",
          width: "100%",
          overflowX: "auto",
        }}
      >
        {loading ? (
          licente.map((licenta) => (
            <LicenteContainer
              key={licenta.id}
              nume={licenta.serial}
              valoare={licenta.expire}
              id={licenta.id}
              data={data}
              hwid={licenta.hwid}
              deviceName={licenta.deviceName}
              appId={licenta.appId}
              setTrigger={setTrigger}
              existsInFirestore={licenta.existsInFirestore}
              onCreate={() =>
                handleCreateLicenseInFirestore(licenta.cui, licenta)
              }
              match={match}
              onClick={() => handleLicenseClick(licenta)}
              onCreateLicenta={() => handleCreateLicenseInLicentiere(licenta)}
            />
          ))
        ) : (
          <Spinner />
        )}
      </div>

      {/* <Button onClick={() => createAccount('DB4343ff434')}>Creaza licenta Test</Button> */}
    </div>
  );
};

export default AiPosView;

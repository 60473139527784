import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import md5 from "md5";
import { useAuth } from "./AuthContext";
import { useNavigate, Link } from "react-router-dom";
// import sma from "../../../images/smaTitle.png";
import MySvgIcon2 from "../../../images/AiPosV2";
import "./LoginPage.css";
import { auth, db } from "../../../utils/firebase/firebase";

import { signInWithEmailAndPassword } from "firebase/auth";

const headerStyle = {
  fontSize: "40px",
  fontWeight: 500,
};

const textFieldStyle = {
  marginBottom: "16px",
  width: "100%",
};

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [firebaseError, setFirebaseError] = useState(null);
  const { setIsAuthenticated, setUserData } = useAuth();
  const navigate = useNavigate();

  const currentDate = new Date();

  const sevenDaysLater = new Date(currentDate);
  const expireDate = sevenDaysLater.setDate(currentDate.getDate() + 7);

  // const futureDate = new Date(currentDate.getTime() + 10 * 1000);

  const handleEmailChange = (event) => {
    const trimmedEmail = event.target.value.trim();
    setEmail(trimmedEmail);
  };

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginClick = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("User logged in:", user.uid);

      const loginData = user;

      localStorage.setItem("expireDate", expireDate);
      localStorage.setItem("login", true);

      setUserData(loginData);
      setIsAuthenticated(true);
      navigate("/ai_pos/dashboard");
      setFirebaseError(null); // Asigură-te că resetați eroarea când login-ul este succes
    } catch (error) {
      console.log(error);
      setFirebaseError(error); // Traduce eroarea aici
    }
  };

  const getErrorMessage = () => {
    if (!firebaseError) return ''; // Verifică dacă există o eroare
    const errorCode = firebaseError.code; // Accesează codul numai dacă eroarea există
    const errorMessages = {
      'auth/invalid-credential': 'Credențiale invalide, email sau parolă greșită.',
      'auth/user-not-found': 'Nu există un cont asociat cu acest email.',
      'auth/wrong-password': 'Credențiale invalide, email sau parolă greșită.',
      'auth/email-already-in-use': 'Emailul este deja utilizat de un alt cont.',
      'auth/weak-password': 'Parola este prea simplă. Vă rugăm să alegeți o parolă mai complexă.',
      // adaugă aici alte coduri de eroare după cum este necesar
    };
    return errorMessages[errorCode] || 'A apărut o eroare necunoscută. Vă rugăm să încercați din nou.';
  };
  


  return (
    <div
      style={{
        marginTop: "7%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="login-screen">
        <Typography style={headerStyle}>Bine ați venit la</Typography>
        <MySvgIcon2 />
        <br />
        <br />

        <Typography style={{ fontSize: "17px" }}>
          Suntem încântați să vă avem alături! Vă rugăm să vă conectați pentru a
          accesa platforma noastră!
        </Typography>
        <br />


        <form onSubmit={handleLoginClick}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            style={textFieldStyle}
            value={email}
            onChange={handleEmailChange}
            required
          />
          <TextField
            id="outlined-basic"
            label="Parolă"
            variant="outlined"
            type="password"
            style={textFieldStyle}
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <Link to="/forgot-password" style={{ textDecoration: "underlined", color: "blue" }}>
              Ați uitat parola?
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleLoginClick}
            >
              Autentificare
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={() => navigate("/signup")}
            >
              Înregistrare
            </Button>
          </div>
          {firebaseError && (
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "16px", color: "red", fontWeight: "700" }}>
              <div className="error" style={{ textAlign: "center" }}>{getErrorMessage()}</div>
            </div>
          )}

        </form>
      </div>
    </div>
  );
};

export default Login;

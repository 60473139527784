import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { licenteArray } from "./aiPosContainer";
import { Paper } from "@mui/material";
import {
  Button,
  Modal,
  Box,
  TextField,
  Collapse,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import "./listaGestiune.css";
import { db } from "../../utils/firebase/firebase";
import { useLicentaContext } from "./context/licentaContext";
import { useLicentaDataContext } from "./context/licentaDataContext";

import { useSnackbar } from "notistack";

import {
  addDoc,
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Contract from "./inventar/raportInventarCentralPDF";
import { generateExcelFile } from "./raportTranzactii";
import { PDFDownloadLink } from "@react-pdf/renderer";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LicentaView = () => {
  const { id } = useParams();
  const { licentaData, setLicentaData } = useLicentaContext();
  const [licenseData, setLicenseData] = useState({
    products: [],
    transactions: [],
    businessInfo: {},
    nomenclatoare: {
      categories: [],
      ums: [],
      tvas: [],
      sgrs: [],
    },
    updates: [],
  });

  const [handleTrigger, setHandleTrigger] = useState("untested");
  const [openNomenclator, setOpenNomenclator] = useState(false);

  const [licenteNomenclatoare, setLicenteNomenclatoare] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  // category edit modal
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const handleEditCategoryClick = (category) => {
    setCurrentCategory(category);
    setEditCategoryModalOpen(true);
  };

  // add or edit for products
  const [modalMode, setModalMode] = useState("add");

  const [currentProduct, setCurrentProduct] = useState({
    id: "",
    name: "",
    um: "",
    price: "",
    tva: "",
    category: "",
    tip: "",
    codBare: "",
    stoc: "",
    cantaribil: "",
    sgr: "",
  });

  // const handleEditProduct = (product) => {
  //   // Populate form fields from the existing product in Firestore
  //   setNume(product.name || ""); // Name
  //   setUmProdus(product.um || ""); // UM (Unit of Measure)
  //   setCategorieProdus(product.category || ""); // Category
  //   setPret(product.price || ""); // Price
  //   setTva(product.tva || ""); // VAT
  //   setTipProdus(product.tip !== undefined ? product.tip : ""); // Product Type
  //   setCodBare(product.cod || ""); // Barcode
  //   setStoc(product.stoc || ""); // Stock
  //   setCantaribil(product.cantaribil !== undefined ? product.cantaribil : ""); // Weighable
  //   setSgr(product.sgr || ""); // SGR (Returnable)

  //   setCurrentProduct(product); // Set the current product being edited
  //   setModalMode("edit"); // Set the modal mode to 'edit'
  //   setOpenMaterial(true); // Open the modal
  // };

  const handleEditProduct = (product) => {
    // Populate form fields from the existing product in Firestore
    setNume(product.name || ""); // Name
    setUmProdus(product.um || ""); // UM (Unit of Measure)
    setCategorieProdus(product.category || ""); // Category
    setPret(product.price || ""); // Price
    setTva(product.tva || ""); // VAT
    setTipProdus(product.tip !== undefined ? product.tip : ""); // Product Type
    setCodBare(product.codBare || ""); // Barcode
    setStoc(product.stoc || ""); // Stock
    setCantaribil(product.cantaribil !== undefined ? product.cantaribil : ""); // Weighable
    setSgr(product.sgr || ""); // SGR (Returnable)

    setCurrentProduct(product); // Set the current product being edited
    setModalMode("edit"); // Set the modal mode to 'edit'
    setOpenMaterial(true); // Open the modal
  };

  const resetForm = () => {
    setNume("");
    setUmProdus("");
    setCategorieProdus("");
    setPret("");
    setTva("");
    setTipProdus("");
    setCodBare("");
    setStoc("");
    setCantaribil("");
    setSgr("");
    setNewCategory(""); // Resetting this if it's part of your form
  };

  const handleAddProduct = () => {
    resetForm(); // Clears all form fields
    setModalMode("add");
    setOpenMaterial(true);
  };

  const [umsValue, setUmsValue] = useState("");

  const { licentaDataVal } = useLicentaDataContext();

  useEffect(() => {
    // Check if licentaData is available in context
    if (licentaData && licentaData.serial && licentaData.cui) {
      localStorage.setItem("licentaData", JSON.stringify(licentaData));
      readData(licentaData.cui, licentaData.serial);
    } else {
      // If not, try to load it from local storage
      const storedLicentaData = JSON.parse(localStorage.getItem("licentaData"));
      if (storedLicentaData) {
        setLicentaData(storedLicentaData);
        readData(storedLicentaData.cui, storedLicentaData.serial);
      }
    }
  }, [licentaData, setLicentaData, openNomenclator, handleTrigger]);

  const readData = async (cui, licenseId) => {
    const docRef = doc(db, cui, licenseId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLicenseData(docSnap.data());
        setBusinessAddress(docSnap.data().businessInfo.address);
        setBusinessName(docSnap.data().businessInfo.name);
        setBusinessPhone(docSnap.data().businessInfo.phone);
        setLicenteNomenclatoare(docSnap.data().nomenclatoare);
        // console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (e) {
      console.error("Error fetching document:", e);
      alert(e);
    }
  };

  const iteme = licenseData?.products || [];

  const [openMaterial, setOpenMaterial] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const [openBon, setOpenBon] = useState(false);
  const [bonData, setBonData] = useState({});

  // testing for transactions
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [transactionPret, setTransactionPret] = useState("");
  const [transactionData, setTransactionData] = useState("");
  const [transactionDescriere, setTransactionDescriere] = useState("");

  const handleEditForm = (item) => {
    setFormEdit(item);
    setOpenEdit(true);
  };

  const updateForm = (value) => {
    setFormEdit((prev) => ({ ...prev, ...value }));
  };

  // const [nume, setNume] = useState();
  // const [um, setUm] = useState();
  // const [tva, setTva] = useState();
  // const [pret, setPret] = useState();
  const [nume, setNume] = useState("");
  const [categorieProdus, setCategorieProdus] = useState();
  const [umProdus, setUmProdus] = useState("");
  const [tva, setTva] = useState("");
  const [pret, setPret] = useState("");
  const [tipProdus, setTipProdus] = useState(false);
  const [codBare, setCodBare] = useState("");
  const [stoc, setStoc] = useState("");
  const [cantaribil, setCantaribil] = useState("");
  const [sgr, setSgr] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newUm, setNewUm] = useState("");
  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);

  //  businessInfo
  const [openBusinessInfoModal, setOpenBusinessInfoModal] = useState(false); // State for business info modal
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");

  const [selectedNomenclator, setSelectedNomenclator] = useState("");
  const [nomenclatorId, setNomenclatorId] = useState("");
  const [nomenclatorEnabled, setNomenclatorEnabled] = useState("");
  const [nomenclatorValue, setNomenclatorValue] = useState("");
  const [nomenclatorLabel, setNomenclatorLabel] = useState("");

  // Filters for Vanzari
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  //
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNomenclatorEnabled, setEditNomenclatorEnabled] = useState("");
  const [editNomenclatorValue, setEditNomenclatorValue] = useState("");
  const [editNomenclatorLabel, setEditNomenclatorLabel] = useState("");
  const [currentNomenclator, setCurrentNomenclator] = useState(null);

  // search id transactions
  const [searchTerm, setSearchTerm] = useState("");

  // state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [indexAmef, setIndexAmef] = useState(0);
  const [currentType, setCurrentType] = useState("");

  const paginatedMaterials = filteredMaterials.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPagesProducts = Math.ceil(filteredMaterials.length / itemsPerPage);
  const handlePageChangeProduct = (newPage) => {
    if (newPage > 0 && newPage <= totalPagesProducts) {
      setCurrentPage(newPage);
    }
  };

  const handleEditModalOpen = (nomenclator, type) => {
    setCurrentNomenclator(nomenclator); // Store the current nomenclator being edited
    setCurrentType(type); // Store the type of nomenclator

    // Set the label for all nomenclator types
    setEditNomenclatorLabel(nomenclator.label);

    // Set values based on the type
    if (type === "ums") {
      setEditNomenclatorValue(nomenclator.value || ""); // Use umsValue for UMS nomenclators
    } else if (type === "tvas") {
      setEditIndexAmef(nomenclator.indexAmef || ""); // Separate state for Index AMEF
      setEditNomenclatorValue(nomenclator.value || ""); // Separate state for the Value
      setEditNomenclatorEnabled(nomenclator.enabled); // Activat field
    } else if (type === "sgrs") {
      setEditNomenclatorValue(nomenclator.tva || ""); // Use the associated TVA for SGR nomenclators
    }

    setEditModalOpen(true); // Open the modal
  };
  const [editIndexAmef, setEditIndexAmef] = useState("");
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    setFilteredMaterials(iteme);
  }, [licenseData]);

  // useEffect for Filters:
  useEffect(() => {
    let transactions = licenseData?.transactions || [];
    if (startDate) {
      transactions = transactions.filter((transaction) =>
        moment(transaction.date).isSameOrAfter(startDate, "day")
      );
    }
    if (endDate) {
      transactions = transactions.filter((transaction) =>
        moment(transaction.date).isSameOrBefore(endDate, "day")
      );
    }
    if (paymentMethod) {
      transactions = transactions.filter(
        (transaction) => transaction.paymentType === paymentMethod
      );
    }

    console.log("start", startDate);
    setFilteredTransactions(transactions);
  }, [startDate, endDate, paymentMethod, licenseData]);

  // search id useEffect
  useEffect(() => {
    let transactions = licenseData?.transactions || [];
    // Perform combined filtering: existing filters + search by ID
    const results = transactions
      .filter((item) => {
        // Example: some existing filter logic (modify based on your real filters)
        return true; // Replace with your actual filter logic
      })
      .filter((item) =>
        // Apply search filter on ID
        item?.id.toLowerCase().includes(searchTerm.toLowerCase())
      );

    setFilteredTransactions(results);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Pagination Logic: Calculate start and end indexes for slicing data
  const totalPages = Math.ceil(filteredTransactions.length / rowsPerPage);
  const paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page whenever rows per page changes
  };

  // reset filters Button
  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setPaymentMethod("");
    setFilteredTransactions(licenseData?.transactions || []);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };
  const filterItem = (curcat, field) => {
    const newItem = iteme.filter((newVal) => newVal[field] === curcat);
    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  // const addProduct = async () => {
  //   const category =
  //     categorieProdus === "adauga" ? newCategory : categorieProdus;
  //   const ums = umProdus;
  //   const newProduct = {
  //     id: Date.now().toString(), // unique ID for the product
  //     name: nume,
  //     um: ums,
  //     price: pret,
  //     tva: tva,
  //     category: category,
  //     tip: tipProdus,
  //     cod: codBare,
  //     stoc,
  //     cantaribil,
  //     sgr,
  //   };

  //   // setSelectedNomenclator("categories");
  //   // setNomenclatorValue(newCategory);
  //   // setNomenclatorLabel(newCategory);

  //   // if (categorieProdus === "adauga") {
  //   //   addNomenclator(`nomenclatoare.${selectedNomenclator}`);
  //   // }

  //   try {
  //     const docRef = doc(db, licentaData.cui, licentaData.serial);
  //     await updateDoc(docRef, {
  //       products: arrayUnion(newProduct),
  //     });
  //     setLicenseData((prev) => ({
  //       ...prev,
  //       products: [...prev.products, newProduct],
  //     }));
  //     setOpenMaterial(false);
  //     setNume("");
  //     setUmProdus("");
  //     setPret("");
  //     setTva("");
  //     setCategorieProdus("");
  //     setCodBare("");
  //     setStoc("");
  //     setCantaribil("");
  //     setSgr("");
  //     setNomenclatorValue("");
  //     setNomenclatorLabel("");
  //     // setSelectedNomenclator("");
  //     enqueueSnackbar(`Produsul ${nume} a fost adaugat.`, {
  //       variant: "success",
  //     });
  //   } catch (error) {
  //     console.error("Error adding product:", error);
  //   }
  // };

  // edit product part

  const saveProduct = async () => {
    if (modalMode === "add") {
      addProduct();
    } else if (modalMode === "edit") {
      editProduct();
    }
  };

  const editProduct = async () => {
    const updatedProduct = {
      ...currentProduct, // Spread the existing product details
      name: nume || currentProduct.name,
      um: umProdus || currentProduct.um,
      price: pret || currentProduct.price,
      tva: tva || currentProduct.tva,
      category: categorieProdus || currentProduct.category,
      tip: tipProdus !== undefined ? tipProdus : currentProduct.tip,
      codBare: codBare || currentProduct.codBare,
      stoc: stoc || currentProduct.stoc,
      // cantaribil: typeof cantaribil === "boolean" ? cantaribil : Boolean(currentProduct.cantaribil),
      cantaribil:
        typeof cantaribil === "boolean" ? (cantaribil ? 1 : 0) : cantaribil,
      sgr: sgr || currentProduct.sgr,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);

      // Retrieve the existing products from Firestore
      const docSnap = await getDoc(docRef);
      const products = docSnap.data()?.products || [];

      // Create an updated products array with the edited product
      const updatedProducts = products.map((product) =>
        product.id === currentProduct.id ? updatedProduct : product
      );

      // Update Firestore
      await updateDoc(docRef, {
        products: updatedProducts,
        updates: arrayUnion({
          data: updatedProduct,
          updateType: "update",
          group: "products",
        }),
      });

      // Update the local state to reflect the changes
      setLicenseData((prev) => ({
        ...prev,
        products: updatedProducts,
        updates: [
          ...prev.updates,
          {
            data: updatedProduct,
            updateType: "update",
            group: "products",
          },
        ],
      }));

      enqueueSnackbar(`Produsul ${nume} a fost actualizat cu succes.`, {
        variant: "success",
      });

      // Close the modal after successful save
      handleModalMaterialClose();
    } catch (error) {
      console.error("Error updating product:", error);
      enqueueSnackbar("Field-urile trebuie completate integral!", {
        variant: "error",
      });
    }
  };

  const resetProductInputs = () => {
    setCurrentProduct({
      id: "",
      name: "",
      um: "",
      price: "",
      tva: "",
      category: "",
      tip: "",
      cod: "",
      stoc: "",
      cantaribil: "",
      sgr: "",
    });
    setModalMode("add"); // Reset mode to 'add' for future use
  };

  const addProduct = async () => {
    let category = categorieProdus;

    // If adding a new category, create and add it to Firestore
    if (categorieProdus === "adauga" && newCategory) {
      const newCategoryObj = {
        id: uuidv4(), // Generates a unique ID for the category
        label: newCategory,
      };

      try {
        const docRef = doc(db, licentaData.cui, licentaData.serial);
        await updateDoc(docRef, {
          [`nomenclatoare.categories`]: arrayUnion(newCategoryObj),
        });

        // Update local state to include the new category
        setLicenseData((prev) => ({
          ...prev,
          nomenclatoare: {
            ...prev.nomenclatoare,
            categories: [...prev.nomenclatoare.categories, newCategoryObj],
          },
        }));

        category = newCategory; // Set the new category for the product
      } catch (error) {
        console.error("Error adding new category:", error);
        enqueueSnackbar("Eroare la adăugarea categoriei noi.", {
          variant: "error",
        });
        return; // Stop the product addition if category addition fails
      }
    }

    const newProduct = {
      id: Date.now().toString(), // unique ID for the product
      name: nume,
      cantity: 0,
      um: umProdus,
      price: Number(pret),
      tva: tva,
      category: category,
      tip: 0,
      freePrice: 0,
      totalSale: 0,
      count: 0,
      codBare: Number(codBare),
      stoc: Number(stoc),
      sgr: sgr,
      // cantaribil: cantaribil ? 1 : 0,
      cantaribil:
        typeof cantaribil === "boolean" ? (cantaribil ? 1 : 0) : cantaribil,
      // sgr: Number(sgr),
      created: new Date().toISOString(),
    };

    console.log("new", newProduct);

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        products: arrayUnion(newProduct),
        updates: arrayUnion({
          data: newProduct,
          updateType: "add",
          group: "products",
        }),
      });
      setLicenseData((prev) => ({
        ...prev,
        products: [...prev.products, newProduct],
        updates: [
          ...prev.updates,
          { data: newProduct, updateType: "add", group: "products" },
        ],
      }));
      setOpenMaterial(false);
      setNume("");
      setUmProdus("");
      setPret("");
      setTva("");
      setCategorieProdus("");
      setCodBare("");
      setStoc("");
      setCantaribil("");
      setSgr("");
      setNomenclatorValue("");
      setNomenclatorLabel("");
      enqueueSnackbar(`Produsul ${nume} a fost adaugat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // ADD NOMENCLATOR MODIFIED

  // const addNomenclator = async (type) => {
  //   const nomenclatorIdU = uuidv4();

  //   const newProduct = {
  //     id: nomenclatorIdU,
  //     enabled: nomenclatorEnabled ? "Da" : "Nu",
  //     value: nomenclatorValue,
  //     label: nomenclatorLabel,
  //     tva: tva,
  //   };

  //   const simpleType = type.split('.')[1] || type;

  //   try {
  //     const docRef = doc(db, licentaData.cui, licentaData.serial);
  //     await updateDoc(docRef, {
  //       [type]: arrayUnion(newProduct),
  //       updates: arrayUnion({
  //         data: newProduct,
  //         updateType: "add",
  //         group: simpleType,
  //       }),
  //     });

  //     setLicenteNomenclatoare((prev) => ({
  //       ...prev,
  //       nomenclatoare: [...prev[selectedNomenclator], newProduct],
  //       updates: Array.isArray(prev.updates) ? [...prev.updates, { data: newProduct, updateType: "add", group: simpleType, }] : [{ data: newProduct, updateType: "add", group: simpleType }]
  //     }));

  //     handleModalNomenclator();

  //     enqueueSnackbar(`Nomenclatorul ${nomenclatorLabel} a fost adaugat.`, {
  //       variant: "success",
  //     });
  //   } catch (error) {
  //     console.error("Error adding product:", error);
  //   }
  // };
  const newUmsNomenclator = (label, umsValue) => {
    return {
      id: uuidv4(), // Generates a unique ID
      label, // Nume or label for the UMS nomenclator
      value: umsValue, // UMS-specific value
    };
  };
  const newTvaNomenclator = (label, indexAmef, value, enabled) => {
    return {
      id: uuidv4(), // Generates a unique ID
      label,
      value,
      idCasa: indexAmef,
      enabled: enabled ? "Da" : "Nu", // Handle enabled status
    };
  };

  const newSgrsNomenclator = (label, tva, enabled) => {
    return {
      id: uuidv4(), // Generates a unique ID
      label, // Label or name for the SGRS nomenclator
      tva, // Associated TVA value
      enabled: enabled ? "Da" : "Nu", // Handle enabled status
    };
  };

  const addNomenclator = async (type) => {
    const nomenclatorIdU = uuidv4();

    let newNomenclator;

    // Check if the type is UMS, then create the nomenclator using newUmsNomenclator
    if (type === "nomenclatoare.ums") {
      // For UMS nomenclator
      newNomenclator = newUmsNomenclator(nomenclatorLabel, nomenclatorValue);
    } else if (type === "nomenclatoare.tvas") {
      // For TVA nomenclator
      newNomenclator = newTvaNomenclator(
        nomenclatorLabel,
        indexAmef,
        nomenclatorValue,
        nomenclatorEnabled
      );
    } else if (type === "nomenclatoare.sgrs") {
      // For SGRS nomenclator
      newNomenclator = newSgrsNomenclator(
        nomenclatorLabel,
        tva,
        nomenclatorEnabled
      );
    } else {
      // Otherwise, create a standard nomenclator object
      newNomenclator = {
        id: nomenclatorIdU,
        label: nomenclatorLabel, // Always have label
        enabled: nomenclatorEnabled ? "Da" : "Nu", // Handle enabled status
        value: nomenclatorValue || "", // Set value only if it exists
      };
    }

    const simpleType = type.split(".")[1] || type;

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        [type]: arrayUnion(newNomenclator), // Add the nomenclator (whether UMS or not)
        updates: arrayUnion({
          data: newNomenclator,
          updateType: "add",
          group: simpleType,
        }),
      });

      setLicenteNomenclatoare((prev) => ({
        ...prev,
        nomenclatoare: [...prev[selectedNomenclator], newNomenclator],
        updates: Array.isArray(prev.updates)
          ? [
              ...prev.updates,
              { data: newNomenclator, updateType: "add", group: simpleType },
            ]
          : [{ data: newNomenclator, updateType: "add", group: simpleType }],
      }));

      handleModalNomenclator();

      enqueueSnackbar(`Nomenclatorul ${nomenclatorLabel} a fost adaugat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // testing purpose transactions
  const addTransaction = async () => {
    const newTransaction = {
      id: transactionId,
      amount: transactionPret,
      date: transactionData,
      description: transactionDescriere,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        transactions: arrayUnion(newTransaction),
      });
      setLicenseData((prev) => ({
        ...prev,
        transactions: [...prev.transactions, newTransaction],
      }));
      setOpenTransactionModal(false);
      setTransactionId("");
      setTransactionPret("");
      setTransactionData("");
      setTransactionDescriere("");
    } catch (error) {
      console.error("Error adding transaction:", error);
    }
  };

  const updateMaterial = async () => {
    const category =
      formEdit.category === "adauga" ? newCategory : formEdit.category;
    const updatedProducts = licenseData.products.map((product) =>
      product.id === formEdit.id ? { ...formEdit, category } : product
    );

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        products: updatedProducts,
      });
      setLicenseData((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
      setOpenEdit(false);
      enqueueSnackbar(`Produsul ${updatedProducts[0].name} a fost modificat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error updating material:", error);
    }
  };

  // add business info
  const updateBusinessInfo = async () => {
    const updatedBusinessInfo = {
      address: businessAddress,
      name: businessName,
      phone: businessPhone,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        businessInfo: updatedBusinessInfo,
      });
      setLicenseData((prev) => ({
        ...prev,
        businessInfo: updatedBusinessInfo,
      }));
      setOpenBusinessInfoModal(false);
    } catch (error) {
      console.error("Error updating business info:", error);
    }
  };

  const deleteProduct = async (productId) => {
    const productToDelete = licenseData.products.find(
      (product) => product.id === productId
    );

    if (!productToDelete) {
      console.error("Product not found!");
      return;
    }

    const updatedProducts = licenseData.products.filter(
      (product) => product.id !== productId
    );

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        products: updatedProducts,
        updates: arrayUnion({
          data: productToDelete,
          updateType: "delete",
          group: "products",
        }),
      });
      enqueueSnackbar(`Produsul ${productToDelete.name} a fost șters.`, {
        variant: "error",
      });
      setLicenseData((prev) => ({
        ...prev,
        products: updatedProducts,
        updates: [
          ...prev.updates,
          {
            data: productToDelete,
            updateType: "delete",
            group: "products",
          },
        ],
      }));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleModalMaterialClose = () => {
    setOpenMaterial(false);
    setNewCategory("");
    setCategorieProdus("");
    resetForm();
  };

  const handleModalTransactionClose = () => {
    setOpenTransactionModal(false);
    setTransactionId("");
    setTransactionPret("");
    setTransactionData("");
    setTransactionDescriere("");
  };

  const handleModalNomenclator = () => {
    setOpenNomenclator(false);
    setNomenclatorEnabled("");
    setNomenclatorValue("");
    setNomenclatorLabel("");
    setNomenclatorId("");
  };

  const handleNomenclatorClick = (type) => {
    setSelectedNomenclator(type);
    setOpenNomenclator(true);
  };

  const handleModalBusinessInfoClose = () => {
    setOpenBusinessInfoModal(false);
    setBusinessAddress("");
    setBusinessName("");
    setBusinessPhone("");
  };

  const handleBonClick = (item) => {
    setBonData(item);
    console.log("bon", item);
    setOpenBon(true);
  };

  console.log(licenseData);

  const [value, setValue] = React.useState(0);
  const [nomenclatoareTab, setNomenclatoareTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNomenclatoareChange = (event, newValue) => {
    setNomenclatoareTab(newValue);
  };

  console.log(licenseData);

  // delete nomenclator function
  // const deleteNomenclatoare = async (nomenclatorId, type) => {
  //   const nomenclatorToDelete = licenseData.nomenclatoare[type].find(
  //     (nomenclator) => nomenclator.id === nomenclatorId
  //   );

  //   if (!nomenclatorToDelete) {
  //     console.error("Nomenclator not found!");
  //     return;
  //   }

  //   // Check if any product is using this nomenclator
  //   const isNomenclatorInUse = licenseData.products.some(
  //     (product) => product[type.slice(0, -1)] === nomenclatorId
  //   );

  //   if (isNomenclatorInUse) {
  //     enqueueSnackbar(
  //       `Nomenclatorul ${nomenclatorToDelete.label} nu poate fi șters deoarece este folosit în produse.`,
  //       {
  //         variant: "warning", // A less severe notification
  //       }
  //     );
  //     return; // Prevent deletion
  //   }

  //   const updatedNomenclatoare = {
  //     ...licenseData.nomenclatoare,
  //     [type]: licenseData.nomenclatoare[type].filter(
  //       (nomenclator) => nomenclator.id !== nomenclatorId
  //     ),
  //   };

  //   try {
  //     const docRef = doc(db, licentaData.cui, licentaData.serial);
  //     await updateDoc(docRef, {
  //       nomenclatoare: updatedNomenclatoare,
  //     });
  //     enqueueSnackbar(
  //       `Nomenclatorul ${nomenclatorToDelete.label} a fost șters.`,
  //       {
  //         variant: "error",
  //       }
  //     );
  //     setLicenseData((prev) => ({
  //       ...prev,
  //       nomenclatoare: updatedNomenclatoare,
  //     }));
  //   } catch (error) {
  //     console.error("Error deleting nomenclator:", error);
  //   }
  // };
  const deleteNomenclatoare = async (nomenclatorId, type) => {
    console.log(
      "Attempting to delete nomenclator with ID:",
      nomenclatorId,
      "from type:",
      type
    );

    // Check existence and filter out the nomenclator
    const existingNomenclatores = licenseData.nomenclatoare[type] || [];
    const nomenclatorToDelete = existingNomenclatores.find(
      (n) => n.id === nomenclatorId
    );

    if (!nomenclatorToDelete) {
      console.error("Nomenclator not found!");
      return;
    }

    // Verify if it's in use
    const isNomenclatorInUse = licenseData.products.some(
      (product) => product[type.slice(0, -1)] === nomenclatorId
    );
    if (isNomenclatorInUse) {
      enqueueSnackbar(
        `Nomenclatorul ${nomenclatorToDelete.label} nu poate fi șters deoarece este folosit în produse.`,
        { variant: "warning" }
      );
      return;
    }

    // Proceed with deletion
    const updatedNomenclatoare = existingNomenclatores.filter(
      (n) => n.id !== nomenclatorId
    );

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        [`nomenclatoare.${type}`]: updatedNomenclatoare,
        updates: arrayUnion({
          data: nomenclatorToDelete,
          updateType: "delete",
          group: type,
        }),
      });
      enqueueSnackbar(
        `Nomenclatorul ${nomenclatorToDelete.label} a fost șters.`,
        { variant: "error" }
      );
      setLicenseData((prev) => ({
        ...prev,
        nomenclatoare: {
          ...prev.nomenclatoare,
          [type]: updatedNomenclatoare,
        },
        updates: [
          ...prev.updates,
          {
            data: nomenclatorToDelete,
            updateType: "delete",
            group: type,
          },
        ],
      }));
    } catch (error) {
      console.error("Error deleting nomenclator:", error);
    }
  };

  // edit category function
  const editCategory = async (category) => {
    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      // Update the specific category based on its ID
      const updatedCategories = licenseData.nomenclatoare.categories.map(
        (item) =>
          item.id === category.id ? { ...item, label: category.label } : item
      );

      const updateRecord = {
        data: { label: category.label, id: category.id },
        updateType: "update",
        group: "categories",
        // timestamp: new Date().toISOString()
      };

      await updateDoc(docRef, {
        "nomenclatoare.categories": updatedCategories,
        updates: arrayUnion(updateRecord),
      });

      // Update local state to reflect changes
      setLicenseData((prev) => ({
        ...prev,
        nomenclatoare: { ...prev.nomenclatoare, categories: updatedCategories },
        updates: [...prev.updates, updateRecord],
      }));

      enqueueSnackbar("Category updated successfully", { variant: "success" });
    } catch (error) {
      console.error("Error updating category:", error);
      enqueueSnackbar("Error updating category", { variant: "error" });
    }
  };

  // edit nomenclator Button
  // const editNomenclator = async (type, nomenclator) => {
  //   const updatedNomenclator = {
  //     ...nomenclator,
  //     enabled: editNomenclatorEnabled,
  //     value: editNomenclatorValue,
  //     label: editNomenclatorLabel,
  //   };

  //   try {
  //     const docRef = doc(db, licentaData.cui, licentaData.serial);
  //     const docSnap = await getDoc(docRef);
  //     const nomenclatoare = docSnap.data()?.nomenclatoare || {};

  //     const updatedNomenclatoare = {
  //       ...nomenclatoare,
  //       [type]: nomenclatoare[type].map((item) =>
  //         item.id === nomenclator.id ? updatedNomenclator : item
  //       ),
  //     };

  //     await updateDoc(docRef, {
  //       nomenclatoare: updatedNomenclatoare,
  //       updates: arrayUnion({
  //         data: updatedNomenclator,
  //         updateType: "update",
  //         group: type
  //       })
  //     });

  //     // Update local state
  //     setLicenteNomenclatoare((prev) => ({
  //       ...prev,
  //       nomenclatoare: {
  //         ...prev.nomenclatoare,
  //         [type]: updatedNomenclatoare[type],
  //       },
  //       updates: Array.isArray(prev.updates) ? [...prev.updates, {
  //         data: updatedNomenclator,
  //         updateType: "update",
  //         group: type
  //       }] : [{ data: updatedNomenclator, updateType: "update", group: type }]
  //     }));

  //     enqueueSnackbar(`Nomenclatorul ${nomenclator.label} a fost actualizat.`, {
  //       variant: "success",
  //     });

  //     // Immediately update the local state for UI
  //     licenseData.nomenclatoare[type] = updatedNomenclatoare[type];
  //   } catch (error) {
  //     console.error("Error updating nomenclator:", error);
  //   }
  // };
  const editNomenclator = async (type, nomenclator) => {
    const updatedNomenclator = {
      ...nomenclator,
      label: editNomenclatorLabel,
      ...(type === "tvas" && {
        idCasa: editIndexAmef,
        value: editNomenclatorValue,
      }), // Update indexAmef for TVA
      ...(type === "ums" && { value: editNomenclatorValue }), // Update umsValue for UMS
      ...(type === "sgrs" && { tva: editNomenclatorValue }), // Update associated tva for SGR
      ...(type === "tvas" && { enabled: editNomenclatorEnabled }), // Handle 'Activat' for TVA
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      const docSnap = await getDoc(docRef);
      const nomenclatoare = docSnap.data()?.nomenclatoare || {};

      const updatedNomenclatoare = {
        ...nomenclatoare,
        [type]: nomenclatoare[type].map((item) =>
          item.id === nomenclator.id ? updatedNomenclator : item
        ),
      };

      await updateDoc(docRef, {
        nomenclatoare: updatedNomenclatoare,
        updates: arrayUnion({
          data: updatedNomenclator,
          updateType: "update",
          group: type,
        }),
      });

      setLicenteNomenclatoare((prev) => ({
        ...prev,
        nomenclatoare: {
          ...prev.nomenclatoare,
          [type]: updatedNomenclatoare[type],
        },
        updates: Array.isArray(prev.updates)
          ? [
              ...prev.updates,
              { data: updatedNomenclator, updateType: "update", group: type },
            ]
          : [{ data: updatedNomenclator, updateType: "update", group: type }],
      }));

      // Update the `licenseData` state if you're managing the main state separately
      setLicenseData((prev) => ({
        ...prev,
        nomenclatoare: {
          ...prev.nomenclatoare,
          [type]: updatedNomenclatoare[type],
        },
      }));

      enqueueSnackbar(`Nomenclatorul ${nomenclator.label} a fost actualizat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error updating nomenclator:", error);
    }
  };

  console.log("licentaData", licentaData);

  const [filteredTransactionsForExcel, setFilteredTransactionsForExcel] =
    useState([]);
  const [excelObj, setExcelObj] = useState({});

  const getProductsForExcel = () => {
    let transactionsForExcel = [];

    filteredTransactions.forEach((item) => {
      // Iterate over each product in item.produse
      const productsWithBonNr = item.produse.map((product) => ({
        ...product,
        bonNr: item.bonNr,
        total: Number(product.count) * Number(product.price), // Calculate the total
      }));

      // Concatenate the modified products into the transactionsForExcel array
      transactionsForExcel = transactionsForExcel.concat(productsWithBonNr);
    });

    // Check if startDate and endDate are defined; if not, set defaults
    const formattedStartDate = startDate
      ? moment(startDate).format("DD/MM/YYYY")
      : "De la inceput";
    const formattedEndDate = endDate
      ? moment(endDate).format("DD/MM/YYYY")
      : moment().format("DD/MM/YYYY");

    const excelObjData = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      current: moment().format("DD/MM/YYYY"),
    };

    setExcelObj(excelObjData);

    setFilteredTransactionsForExcel(transactionsForExcel);
  };

  useEffect(() => {
    getProductsForExcel();

    console.log("filt", licenseData?.nomenclatoare);
  }, [filteredTransactions]);

  // ------------- TEST FUNCTIONS ---------------

  const userData = {
    cui: licentaData?.cui,
    licenta: licentaData?.serial, // HF-0001-ULR8-5E08
    licentaCloud: true,
  };

  const testNomenclatoare = {
    sgr: [
      {
        id: "SGR Sticla-0.5",
        label: "SGR Sticla",
        value: "1",
        price: "0.5",
        tva: "0",
      },
      {
        id: "SGR Aluminiu-0.5",
        label: "SGR Aluminiu",
        value: "3",
        price: "0.5",
        tva: "0",
      },
      {
        id: "SGR Plastic-0.5",
        label: "SGR Plastic",
        value: "2",
        price: "0.5",
        tva: "0",
      },
    ],
    tvas: [
      { id: "19", label: "TVA 19%", value: "19", enabled: "1", idCasa: "1" },
      { id: "9", label: "TVA 9%", value: "9", enabled: "0", idCasa: "2" },
      { id: "5", label: "TVA 5%", value: "5", enabled: "0", idCasa: "3" },
      { id: "0", label: "TVA 0%", value: "0", enabled: "0", idCasa: "4" },
      {
        id: "neplatitorTva",
        label: "Neplatitor TVA",
        value: "neplatitorTva",
        enabled: "0",
        idCasa: "5",
      },
      {
        id: "scutitTva",
        label: "Scutit TVA",
        value: "scutitTva",
        enabled: "0",
        idCasa: "7",
      },
    ],
    ums: [
      { id: "buc", label: "Bucata", value: "buc", enabled: "1" },
      { id: "kg", label: "Kilogram", value: "kg", enabled: "0" },
      { id: "L", label: "Litru", value: "L", enabled: "0" },
      { id: "set", label: "Set", value: "set", enabled: "0" },
      { id: "bax", label: "Bax", value: "bax", enabled: "0" },
      { id: "m", label: "Metru", value: "m", enabled: "0" },
    ],
  };

  async function addArrayToFirestore(
    licentaCloud,
    cui,
    licenseId,
    arrayName,
    newItem
  ) {
    if (licentaCloud) {
      const docRef = doc(db, cui, licenseId);

      try {
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          alert("License document does not exist.");
          return;
        }

        await updateDoc(docRef, {
          [arrayName]: arrayUnion(...newItem),
        });
      } catch (error) {
        console.error("Error adding product:", error);
        alert("Internal server error");
      }
    }
  }

  const handleAddSgr = () => {
    addArrayToFirestore(
      userData.licentaCloud,
      userData.cui,
      userData.licenta,
      "nomenclatoare.sgrs",
      testNomenclatoare.sgr
    );
  };

  const handleAddTva = () => {
    addArrayToFirestore(
      userData.licentaCloud,
      userData.cui,
      userData.licenta,
      "nomenclatoare.tvas",
      testNomenclatoare.tvas
    );
  };

  const handleAddUms = () => {
    addArrayToFirestore(
      userData.licentaCloud,
      userData.cui,
      userData.licenta,
      "nomenclatoare.ums",
      testNomenclatoare.ums
    );
  };

  const handleTestData = () => {
    handleAddSgr();
    handleAddTva();
    handleAddUms();
    setHandleTrigger("updated");
    alert("Se incarca datele...");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "20px",
          width: "100%",
        }}
      >
        <div>
          <h3>Licența: {licentaData?.serial}</h3>
          <span>CUI: {licentaData?.cui}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: " column",
          }}
        >
          <Button
            variant="contained"
            style={{ background: "green" }}
            onClick={handleTestData}
          >
            Adauga Test data
          </Button>{" "}
          <span> * doar pentru testare</span>
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "12px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Produse" {...a11yProps(0)} />
          <Tab label="Nomenclatoare" {...a11yProps(1)} />
          <Tab label="Vanzari" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Paper elevation={3} style={{ padding: 12 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h2>Produse:</h2>

            <IconButton
              onClick={handleAddProduct}
              sx={{
                backgroundColor: "#00054e", // Same background color as before
                "&:hover": {
                  backgroundColor: "#000030", // Darker hover color
                },
                color: "white", // Ensures the icon is visible
                padding: "6px", // Adjust padding for a compact look
                borderRadius: "4px",
                width: "60px", // Maintain a rectangular shape
                // Keep it square for the icon
                fontSize: "20px", // Keep the icon size consistent
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                marginBottom: "20px",
                justifyContent: "space-between",
                padding: "10px 5px 5px 0px",
              }}
            >
              {/* Searchable Dropdown */}
              <Autocomplete
                id="category-search"
                options={[
                  { label: "Toate", value: "all" }, // Add the 'Toate' option
                  ...(licenseData?.nomenclatoare?.categories || []), // Category options
                ]}
                getOptionLabel={(option) => option.label || ""} // Display category label
                onChange={
                  (event, value) =>
                    value === null || value?.value === "all" // If cleared (null) or "Toate" is selected
                      ? setFilteredMaterials(iteme) // Show all items
                      : filterItem(value?.label, "category") // Filter by category
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Căutare Categorie"
                    placeholder="Selectează Categorie"
                  />
                )}
                sx={{ width: 200 }} // Adjust width of the dropdown
                clearOnEscape // Allows clearing the selection with Esc key as well
              />

              {/* <Autocomplete
                id="tva-search"
                options={[
                  { label: "Toate", value: "all" }, // Add the 'Toate' option for TVA
                  ...(licenseData?.nomenclatoare?.tvas?.map((tva) => ({
                    label: tva.label, // Display the label
                    value: tva.indexAmef, // Use indexAmef for the value
                  })) || []), // Get TVA options, with indexAmef as value
                ]}
                getOptionLabel={(option) => option.label || ""} // Display TVA label in the dropdown
                onChange={
                  (event, value) =>
                    value === null || value?.value === "all" // If cleared (null) or "Toate" is selected
                      ? setFilteredMaterials(iteme) // Show all items when no selection or "Toate"
                      : filterItem(value?.value, "tva") // Filter by `indexAmef`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Căutare TVA"
                    placeholder="Selectează TVA"
                  />
                )}
                sx={{ width: "200px" }}
                clearOnEscape // Allows clearing the selection with Esc key as well
              /> */}
              {/* <Autocomplete
                id="name-search"
                options={[
                  { label: "Toate", value: "all" }, // Add the 'Toate' option for names
                  ...(iteme?.map((item) => ({
                    label: item.name,
                    value: item.name,
                  })) || []), // Get product name options
                ]}
                getOptionLabel={(option) => option.label || ""} // Display product name
                onChange={
                  (event, value) =>
                    value === null || value?.value === "all" // If cleared (null) or "Toate" is selected
                      ? setFilteredMaterials(iteme) // Show all items when no selection or "Toate"
                      : filterItem(value?.value, "name") // Filter by product name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Căutare Nume Produs"
                    placeholder="Selectează Nume Produs"
                  />
                )}
                sx={{ width: "210px" }} // Adjust the width of the dropdown
                clearOnEscape // Allows clearing the selection with Esc key as well
              /> */}
              <Autocomplete
                id="name-search"
                freeSolo // Allow free typing
                options={iteme.map((item) => item.name)} // Provide names as options but allow free typing
                onInputChange={(event, value) => {
                  // Perform filtering based on the input value
                  if (!value || value === "all") {
                    setFilteredMaterials(iteme); // Show all items if the input is empty
                  } else {
                    const filtered = iteme.filter(
                      (item) =>
                        item.name.toLowerCase().includes(value.toLowerCase()) // Match names
                    );
                    setFilteredMaterials(filtered); // Update the filtered list
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Căutare Nume Produs"
                    placeholder="Introduceți Numele Produsului"
                  />
                )}
                sx={{ width: "210px" }} // Adjust the width of the input field
                clearOnEscape // Allows clearing the input with the Esc key
              />
            </div>
            {/* style={{ display: "flex", justifyContent: "flex-start", marginBottom: "20px" }}> */}
            <div>
              {/* style={{ width: "200px", marginRight: "20px" }}> */}

              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ fontWeight: 500, fontSize: "18px" }}>
                  Categorii
                </span>
              </div> */}
              {/* <div style={{ marginTop: "8px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setFilteredMaterials(iteme)}
                  style={{ background: "#00054e" }}
                >
                  Toate
                </Button>
              </div> */}
              {/* {licenseData?.nomenclatoare?.categories?.map((category) => (
                <div style={{ marginTop: "8px" }}>
                  <Button
                    key={category.label}
                    variant="contained"
                    style={{ background: "#00054e" }}
                    fullWidth
                    onClick={() => filterItem(category.label, "category")}
                  >
                    {category.label}
                  </Button>
                </div>
              ))} */}
            </div>
            {/* <div className="mobile-agro">
              <Button
                variant="contained"
                onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
                style={{ background: "#00054e" }}
              >
                Categorii
              </Button>
              <Collapse in={openCategoryMobile}>
                <div
                  style={{
                    borderRight: "1px solid #ccc",
                    padding: "12px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                >
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => handleAllCategory(iteme)}
                      style={{ background: "#00054e" }}
                    >
                      Toate
                    </Button>
                  </div>
                  {licenseData?.nomenclatoare?.categories?.map((category) => (
                    <div style={{ marginTop: "8px" }}>
                      <Button
                        key={category.label}
                        variant="contained"
                        fullWidth
                        onClick={() => filterItem(category.label, "category")}
                      >
                        {category.label}
                      </Button>
                    </div>
                  ))}
                </div>
              </Collapse>
            </div> */}
            <div style={{ flexGrow: "1" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Nume
                    </th>
                    <th style={{ paddingRight: "30px" }}>Categorie</th>
                    <th style={{ paddingRight: "30px" }}>UM</th>
                    <th style={{ paddingRight: "30px" }}>TVA</th>
                    <th style={{ paddingRight: "30px" }}>Preț</th>
                    <th style={{ paddingRight: "30px" }}>SGR</th>

                    <th style={{ paddingRight: "30px", width: "200px" }}>
                      Acțiune
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedMaterials.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.category}</td>
                      <td>{item?.um}</td>
                      <td>{item?.tva}</td>
                      <td>{item?.price}</td>
                      <td>{item?.sgr}</td>
                      <td>
                        <IconButton
                          onClick={() => handleEditProduct(item)}
                          sx={{
                            backgroundColor: "none", // Same background color
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "transform  ease-in-out",
                              background: "none", // Slightly darker color on hover
                            },
                            color: "#00054e", // Ensures the icon is visible
                            padding: "6px", // Compact padding
                            borderRadius: "4px",
                            width: "80px", // Keep it smaller and rectangular  // Keep it square
                            fontSize: "20px",
                          }}
                          size="small"
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => deleteProduct(item.id)}
                          sx={{
                            backgroundColor: "#none", // Background color for delete button
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "transform  ease-in-out",
                              background: "none", // Slightly darker color on hover
                            },
                            color: "#cc4f00", // Ensures the icon is visible
                            padding: "6px", // Compact padding
                            borderRadius: "4px",
                            width: "80px", // Keep it smaller and rectangular
                            // Keep it square
                            fontSize: "20px", // Icon size
                          }}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0",
                }}
              >
                <div>
                  <span>Intrări per pagină: </span>
                  <Select
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(e.target.value);
                      setCurrentPage(1); // Reset to first page when items per page changes
                    }}
                  >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </div>

                <div>
                  <Button
                    variant="contained"
                    disabled={currentPage === 1} // Disable if on the first page
                    onClick={() => handlePageChangeProduct(currentPage - 1)} // Go to previous page
                  >
                    Înapoi
                  </Button>
                  <span style={{ margin: "0 10px" }}>
                    Pagina {currentPage} din {totalPagesProducts}
                  </span>
                  <Button
                    variant="contained"
                    disabled={currentPage === totalPagesProducts} // Disable if on the last page
                    onClick={() => handlePageChangeProduct(currentPage + 1)} // Go to next page
                  >
                    Următoarea
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>{" "}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        {/* Nomenclatoare Sub-Tabs */}
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "12px" }}
        >
          <Tabs
            value={nomenclatoareTab}
            onChange={handleNomenclatoareChange}
            aria-label="nomenclatoare tabs"
          >
            <Tab label="UMS" {...a11yProps(0)} />
            <Tab label="TVA" {...a11yProps(1)} />
            <Tab label="SGR" {...a11yProps(2)} />
            <Tab label="Categorii" {...a11yProps(3)} />

            {/* Add more tabs as needed */}
          </Tabs>
        </Box>
        <CustomTabPanel value={nomenclatoareTab} index={0}>
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>UMS:</h2>
                <IconButton
                  onClick={() => handleNomenclatorClick("ums")}
                  sx={{
                    backgroundColor: "#00054e", // Same background color as before
                    "&:hover": {
                      backgroundColor: "#000030", // Darker hover color on hover
                    },
                    color: "white", // Ensures the icon is visible
                    padding: "6px", // Adjust padding for a compact look
                    borderRadius: "4px",
                    width: "60px", // Fixed width
                    fontSize: "20px",
                  }}
                  size="small"
                >
                  <AddIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </div>
              <div className="nomenclatoare-tab">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Nume
                        </th>
                        {/* <th style={{ paddingRight: "30px" }}>Valoare</th> */}
                        <th style={{ paddingRight: "30px" }}>Valoare</th>

                        <th style={{ paddingRight: "30px", width: "12%" }}>
                          Actiune
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.ums?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.label}</td>
                          <td>{item?.value}</td>
                          {/* <td>{item?.value}</td> */}
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                                paddingRight: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() => handleEditModalOpen(item, "ums")}
                                sx={{
                                  marginLeft: "10px",
                                  backgroundColor: "none", // Custom background color
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    transition: "transform  ease-in-out",
                                    background: "none", // Slightly darker color on hover
                                  },
                                  color: "#00054e", // Icon color
                                  padding: "6px", // Padding for smaller size
                                  borderRadius: "4px", // Squared edges instead of round
                                }}
                                size="small" // This makes the button smaller
                              >
                                <EditIcon sx={{ fontSize: 24 }} />{" "}
                                {/* Controls the size of the icon */}
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteNomenclatoare(item.id, "ums")
                                }
                                sx={{
                                  marginLeft: "10px",
                                  backgroundColor: "none", // Keeping the same orange color
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    transition: "transform  ease-in-out",
                                    background: "none", // Slightly darker color on hover
                                  },
                                  color: "#cc4f00", // Icon color to contrast the background
                                  padding: "6px", // Compact size
                                  borderRadius: "4px", // Squared edges
                                }}
                                size="small" // Smaller button
                              >
                                <DeleteIcon sx={{ fontSize: 24 }} />{" "}
                                {/* Controls the size of the icon */}
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
        </CustomTabPanel>

        <CustomTabPanel value={nomenclatoareTab} index={1}>
          {/* <br /> */}
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>TVA:</h2>
                <IconButton
                  onClick={() => handleNomenclatorClick("tvas")}
                  sx={{
                    backgroundColor: "#00054e", // Same background color as before
                    "&:hover": {
                      backgroundColor: "#000030", // Darker hover color if desired
                    },
                    color: "white", // Ensures the icon is visible
                    padding: "6px", // Adjust padding for a compact look
                    borderRadius: "4px",
                    width: "60px", // Maintain a rectangular shape if desired
                  }}
                  size="small"
                >
                  <AddIcon sx={{ fontSize: 20 }} />{" "}
                  {/* Adjust icon size as needed */}
                </IconButton>
              </div>
              <div className="nomenclatoare-tab">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Activat
                        </th>
                        <th style={{ paddingRight: "30px" }}>Nume</th>
                        <th style={{ paddingRight: "30px" }}>Index AMEF</th>
                        <th style={{ paddingRight: "30px" }}>Valoare TVA</th>
                        <th style={{ paddingRight: "30px", width: "12%" }}>
                          Acțiune
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.tvas?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.enabled}</td>
                          <td>{item?.label}</td>
                          <td>{item?.idCasa}</td>
                          <td>{item?.value}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                                paddingRight: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleEditModalOpen(item, "tvas")
                                }
                                sx={{
                                  marginLeft: "10px",
                                  backgroundColor: "none", // Custom background color
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    transition: "transform  ease-in-out",
                                    background: "none", // Slightly darker color on hover
                                  },
                                  color: "#00054e", // Icon color
                                  padding: "6px", // Padding for smaller size
                                  borderRadius: "4px", // Squared edges instead of round
                                }}
                                size="small"
                              >
                                <EditIcon sx={{ fontSize: 24 }} />
                              </IconButton>

                              <IconButton
                                onClick={() =>
                                  deleteNomenclatoare(item.id, "tvas")
                                }
                                sx={{
                                  marginLeft: "10px",
                                  backgroundColor: "none", // Keeping the same orange color
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    transition: "transform  ease-in-out",
                                    background: "none", // Slightly darker color on hover
                                  },
                                  color: "#cc4f00", // Icon color to contrast the background
                                  padding: "6px", // Compact size
                                  borderRadius: "4px", // Squared edges
                                }}
                                size="small"
                              >
                                <DeleteIcon sx={{ fontSize: 24 }} />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
        </CustomTabPanel>

        <CustomTabPanel value={nomenclatoareTab} index={2}>
          {/* <br /> */}
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>SGR:</h2>
                {/* <IconButton
                  onClick={() => handleNomenclatorClick("sgrs")}
                  sx={{
                    backgroundColor: "#00054e", // Same background color as before
                    "&:hover": {
                      backgroundColor: "#000030", // Darker hover color if desired
                    },
                    color: "white", // Ensures the icon is visible
                    padding: "6px", // Adjust padding for a compact look
                    borderRadius: "4px",
                    width: "60px", // Maintain a rectangular shape if desired
                  }}
                  size="small"
                >
                  <AddIcon sx={{ fontSize: 20 }} />
                </IconButton> */}
              </div>
              <div className="nomenclatoare-tab">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Nume
                        </th>
                        {/* <th style={{ paddingRight: "30px" }}>Nume</th> */}
                        <th style={{ paddingRight: "30px" }}>Pret</th>
                        <th style={{ paddingRight: "30px" }}>TVA</th>

                        <th style={{ paddingRight: "30px", width: "12%" }}>
                          Acțiune
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.sgrs?.map((item, index) => (
                        <tr key={index}>
                          {/* <td>{item?.enabled}</td> */}
                          <td>{item?.label}</td>
                          <td>{item?.price}</td>

                          <td>{item?.tva}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                                paddingRight: "10px",
                              }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleEditModalOpen(item, "sgrs")
                                }
                                sx={{
                                  marginLeft: "10px",
                                  backgroundColor: "none", // Custom background color
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    transition: "transform  ease-in-out",
                                    background: "none", // Slightly darker color on hover
                                  },
                                  color: "#00054e", // Icon color
                                  padding: "6px", // Padding for smaller size
                                  borderRadius: "4px", // Squared edges instead of round
                                }}
                                size="small"
                              >
                                <EditIcon sx={{ fontSize: 24 }} />
                              </IconButton>

                              <IconButton
                                onClick={() =>
                                  deleteNomenclatoare(item.id, "sgrs")
                                }
                                sx={{
                                  marginLeft: "10px",
                                  backgroundColor: "none", // Keeping the same orange color
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    transition: "transform  ease-in-out",
                                    background: "none", // Slightly darker color on hover
                                  },
                                  color: "#cc4f00", // Icon color to contrast the background
                                  padding: "6px", // Compact size
                                  borderRadius: "4px", // Squared edges
                                }}
                                size="small"
                              >
                                <DeleteIcon sx={{ fontSize: 24 }} />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
        </CustomTabPanel>

        <CustomTabPanel value={nomenclatoareTab} index={3}>
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>Categorii:</h2>
                <IconButton
                  onClick={() => handleNomenclatorClick("categories")}
                  sx={{
                    backgroundColor: "#00054e", // Same background color as before
                    "&:hover": {
                      backgroundColor: "#000030", // Darker hover color if desired
                    },
                    color: "white", // Ensures the icon is visible
                    padding: "6px", // Adjust padding for a compact look
                    borderRadius: "4px", // Keep the rectangular shape
                    width: "60px", // Set the desired width
                    fontSize: 20, // Ensure text size is 20
                  }}
                  size="small"
                >
                  <AddIcon />
                </IconButton>
              </div>
              <div className="nomenclatoare-tab">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ paddingRight: "30px", width: "90px" }}>
                          Label
                        </th>

                        <th style={{ paddingRight: "30px", width: "15%" }}>
                          Acțiune
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.categories?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>{item?.label}</td>

                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                  paddingRight: "10px",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleEditCategoryClick(item)} // Passing the entire item
                                  sx={{
                                    marginLeft: "10px",
                                    backgroundColor: "none", // Custom background color
                                    "&:hover": {
                                      transform: "scale(1.2)",
                                      transition: "transform  ease-in-out",
                                      background: "none", // Slightly darker color on hover
                                    },
                                    color: "#00054e", // Icon color
                                    padding: "6px", // Padding for smaller size
                                    borderRadius: "4px", // Squared edges instead of round
                                  }}
                                  size="small"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    deleteNomenclatoare(item.id, "categories")
                                  }
                                  sx={{
                                    marginLeft: "10px",
                                    backgroundColor: "none", // Keeping the same orange color
                                    "&:hover": {
                                      transform: "scale(1.2)",
                                      transition: "transform  ease-in-out",
                                      background: "none", // Slightly darker color on hover
                                    },
                                    color: "#cc4f00", // Icon color to contrast the background
                                    padding: "6px", // Compact size
                                    borderRadius: "4px", // Squared edges
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
        </CustomTabPanel>
        {/* <div style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
        </div> */}
        {/* </div> */}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <Paper elevation={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "8px",
              }}
            >
              <h2>Tranzacții:</h2>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "green.light", // normal background color
                  "&:hover": {
                    bgcolor: "green.dark", // hover background color
                  },
                }}
                // extrage excel-ul in fuctie de filtre
                // licenseData?.transactions
                onClick={() =>
                  generateExcelFile(excelObj, filteredTransactionsForExcel)
                }
              >
                {console.log("trx", filteredTransactions, excelObj)}
                Raport Excel
              </Button>
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Mod Plată</em>
                </MenuItem>
                <MenuItem value="numerar">Numerar</MenuItem>
                <MenuItem value="card">Card</MenuItem>
              </Select>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "red.light",
                  "&:hover": { bgcolor: "red.dark" },
                }}
                onClick={resetFilters}
              >
                Resetează Filtre
              </Button>
            </div>

            <div>
              {/* Search bar */}
              <div style={{ marginBottom: "20px" }}>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Caută după Bon Nr"
                  style={{ width: "31.3%", padding: "8px", fontSize: "16px" }}
                />
              </div>
            </div>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px" }}>Valoare</th>
                  <th style={{ paddingRight: "40px" }}>Data</th>
                  <th style={{ paddingRight: "40px" }}>Mod plată</th>
                  <th style={{ paddingRight: "40px" }}>Bon Nr</th>
                </tr>
              </thead>
              <tbody>
                {paginatedTransactions.map((item, index) => (
                  <tr key={index} onClick={() => handleBonClick(item)}>
                    <td>{item?.totalValue} Ron</td>
                    <td>{item?.date}</td>
                    <td>{item?.paymentType}</td>
                    <td>{item?.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {/* Rows Per Page Dropdown */}
              <div>
                <Select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
                <span> Intrări per pagină</span>
              </div>
              {/* Page Navigation */}
              <div>
                <Button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Înapoi
                </Button>
                <span>
                  Pagina {currentPage} din {totalPages}
                </span>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Următoarea
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </CustomTabPanel>

      <Modal
        open={openMaterial}
        onClose={handleModalMaterialClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <h2>Adaugă Produs Nou</h2>
          <br />
          <div style={{ gap: "12px", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "100%", gap: "10px" }}>
              <InputLabel id="demo-simple-select-label">Nume</InputLabel>
              <TextField
                type="text"
                value={nume}
                fullWidth
                onChange={(e) => setNume(e.target.value)}
              />
              <br />

              <InputLabel id="demo-simple-select-label">UMS </InputLabel>
              <Select
                label="UMS"
                value={umProdus}
                fullWidth
                onChange={(e) => {
                  setUmProdus(e.target.value);
                }}
              >
                {licenseData?.nomenclatoare?.ums?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {" "}
                    {/* Use umsValue or equivalent */}
                    {item.label} {/* Display the label */}
                  </MenuItem>
                ))}
              </Select>
              <br />

              <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
              <Select
                label="Categorie"
                value={categorieProdus}
                onChange={(e) => setCategorieProdus(e.target.value)}
                fullWidth
              >
                {licenseData?.nomenclatoare?.categories?.map((item) => (
                  <MenuItem key={item} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem value={"adauga"}>Adaugă Categorie Noua</MenuItem>
              </Select>
              {categorieProdus === "adauga" && (
                <TextField
                  type="text"
                  placeholder="Categorie"
                  value={newCategory}
                  fullWidth
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              )}
              <br />

              <InputLabel id="demo-simple-select-label">TIP PRODUS </InputLabel>
              <Select
                label="Produs"
                value={tipProdus} // Keep it as a number (0 or 1)
                onChange={(e) => setTipProdus(Number(e.target.value))} // Convert to number on change
                fullWidth
              >
                {[
                  { label: "Produs", value: 0 },
                  { label: "Serviciu", value: 1 },
                ].map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <br />

              <InputLabel id="demo-simple-select-label">Pret</InputLabel>
              <TextField
                type="text"
                value={pret}
                fullWidth
                onChange={(e) => setPret(e.target.value)}
              />
              <br />
            </div>

            <div style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">TVA </InputLabel>
              <Select
                label="TVA"
                value={tva} // This holds the selected TVA value
                fullWidth
                onChange={(e) => {
                  const selectedTva = licenseData.nomenclatoare.tvas.find(
                    (item) => item.value === e.target.value
                  ); // Find the selected TVA by value
                  setTva(selectedTva.value); // Store the TVA value for the product
                }}
              >
                {licenseData?.nomenclatoare?.tvas?.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {" "}
                    {/* Use the correct field for value */}
                    {item.label}{" "}
                    {/* Assuming label is what you want to display */}
                  </MenuItem>
                ))}
              </Select>
              <br />

              <InputLabel id="demo-simple-select-label">Cod de bare</InputLabel>
              <TextField
                type="text"
                value={codBare}
                fullWidth
                onChange={(e) => setCodBare(e.target.value)}
              />
              <br />

              <InputLabel id="demo-simple-select-label">Stoc</InputLabel>
              <TextField
                type="text"
                fullWidth
                value={stoc}
                onChange={(e) => setStoc(e.target.value)}
              />
              <br />

              <InputLabel id="demo-simple-select-label">Cantaribil</InputLabel>
              <Select
                label="Cantaribil"
                value={cantaribil} // Use 0 or 1 directly
                onChange={(e) => setCantaribil(Number(e.target.value))} // Convert to number on change
                fullWidth
              >
                {[
                  { label: "Da", value: 1 },
                  { label: "Nu", value: 0 },
                ].map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <br />

              <InputLabel id="demo-simple-select-label">SGR</InputLabel>
              <Select
                label="SGR"
                value={sgr}
                onChange={(e) => {
                  setSgr(e.target.value);
                }}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select SGR</em>
                </MenuItem>
                {licenseData.nomenclatoare.sgrs &&
                  licenseData.nomenclatoare.sgrs.map((item) => (
                    <MenuItem key={item.label} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <br />
            <br />
            <br />
            <br />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button variant="contained" color="success" onClick={saveProduct}>
              {modalMode === "add" ? "Adaugă Produs" : "Editează Produs"}
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openTransactionModal}
        onClose={handleModalTransactionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Adaugă Tranzactie Noua</h2>
            <br />
            <TextField
              type="text"
              placeholder="ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Pret"
              value={transactionPret}
              onChange={(e) => setTransactionPret(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Data"
              value={transactionData}
              onChange={(e) => setTransactionData(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Descriere"
              value={transactionDescriere}
              onChange={(e) => setTransactionDescriere(e.target.value)}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={addTransaction}
            >
              Adaugă Tranzactie
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Actualizeaza: {formEdit.name}</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume"
              value={formEdit.name}
              onChange={(e) => updateForm({ name: e.target.value })}
            />
            <TextField
              type="text"
              placeholder="Um"
              value={formEdit.um}
              onChange={(e) => updateForm({ um: e.target.value })}
            />

            <InputLabel id="demo-simple-select-label">UMS </InputLabel>
            <Select
              label="Categorie"
              value={formEdit.um}
              onChange={(e) => {
                updateForm({ um: e.target.value });
                setNewCategory("");
              }}
            >
              {licenseData?.nomenclatoare?.ums.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adaugă Categorie Noua</MenuItem>
            </Select>
            {formEdit.um === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}
            <TextField
              type="text"
              placeholder="Pret"
              value={formEdit.price}
              onChange={(e) => updateForm({ price: e.target.value })}
            />
            <InputLabel id="demo-simple-select-label">TVA </InputLabel>
            <Select
              label="TVA"
              value={tva}
              onChange={(e) => {
                setTva(e.target.value);
              }}
            >
              {licenseData?.nomenclatoare?.tvas?.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
            <Select
              label="Categorie"
              value={formEdit.category}
              onChange={(e) => {
                updateForm({ category: e.target.value });
                setNewCategory("");
              }}
            >
              {licenseData?.nomenclatoare?.categories?.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adaugă Categorie Noua</MenuItem>
            </Select>
            {formEdit.category === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}
            <br />
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={updateMaterial}
            >
              Actualizeaza Item
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openBon}
        onClose={() => setOpenBon(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <span>Bon ID: {bonData?.id}</span>
            <span>Data: {bonData?.date}</span>
            <span>Tip Plata: {bonData?.paymentType}</span>
            <span>Total: {bonData?.totalValue} Ron</span>
            <br />
            <span>Produse:</span>
            {bonData?.produse?.map((item) => (
              <div>
                <li>Nume: {item.name}</li>
                <li>Categorie: {item.category}</li>
                <li>Pret: {item.price}</li>
                <li>TVA: {item.tva}</li>
                <hr />
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      {/* Edit Modal */}
      {/* <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box sx={styleInv}>
          <h2>Edit Nomenclator</h2>
          <TextField
            margin="dense"
            label="Label"
            type="text"
            fullWidth
            value={editNomenclatorLabel}
            onChange={(e) => setEditNomenclatorLabel(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Value"
            type="text"
            fullWidth
            value={editNomenclatorValue}
            onChange={(e) => setEditNomenclatorValue(e.target.value)}
          />
          <InputLabel>Activat</InputLabel>
          <Select
            value={editNomenclatorEnabled}
            onChange={(e) => setEditNomenclatorEnabled(e.target.value)}
            fullWidth
          >
            <MenuItem value="Da">Da</MenuItem>
            <MenuItem value="Nu">Nu</MenuItem>
          </Select>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={handleEditModalClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                editNomenclator(currentType, currentNomenclator);
                handleEditModalClose();
              }}
              color="primary"
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal> */}
      <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box sx={styleInv}>
          <h2>Edit Nomenclator</h2>

          {/* Common for all types */}
          <TextField
            margin="dense"
            label="Label"
            type="text"
            fullWidth
            value={editNomenclatorLabel}
            onChange={(e) => setEditNomenclatorLabel(e.target.value)}
          />

          {/* Conditionally render based on nomenclator type */}
          {currentType === "ums" && (
            <TextField
              margin="dense"
              label="UMS Value"
              type="text"
              fullWidth
              value={editNomenclatorValue}
              onChange={(e) => setEditNomenclatorValue(e.target.value)}
            />
          )}

          {currentType === "tvas" && (
            <>
              <TextField
                margin="dense"
                label="Index AMEF"
                type="text"
                fullWidth
                value={editIndexAmef}
                onChange={(e) => setEditIndexAmef(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Valoare TVA"
                type="number"
                fullWidth
                value={editNomenclatorValue} // Holds the value property for TVA
                onChange={(e) => setEditNomenclatorValue(e.target.value)}
              />
              <InputLabel>Activat</InputLabel>
              <Select
                value={editNomenclatorEnabled}
                onChange={(e) => setEditNomenclatorEnabled(e.target.value)}
                fullWidth
              >
                <MenuItem value="Da">Da</MenuItem>
                <MenuItem value="Nu">Nu</MenuItem>
              </Select>
            </>
          )}

          {currentType === "sgrs" && (
            <>
              <InputLabel id="tva-label">TVA</InputLabel>
              <Select
                labelId="tva-label"
                value={editNomenclatorValue} // Holds the selected TVA
                onChange={(e) => setEditNomenclatorValue(e.target.value)} // Update the selected TVA
                fullWidth
              >
                {licenseData?.nomenclatoare?.tvas?.map((item) => (
                  <MenuItem key={item.value} value={item.label}>
                    {" "}
                    {/* Use label for display, value for storage */}
                    {item.label} {/* Show label */}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={handleEditModalClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                editNomenclator(currentType, currentNomenclator);
                handleEditModalClose();
              }}
              color="primary"
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add nomenclator modal */}
      <Modal
        open={openNomenclator}
        onClose={handleModalNomenclator}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Adaugă Nomenclator Nou</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume"
              value={nomenclatorLabel}
              onChange={(e) => setNomenclatorLabel(e.target.value)}
            />
            {selectedNomenclator === "ums" && (
              <TextField
                type="text"
                placeholder="UM Value"
                value={nomenclatorValue}
                onChange={(e) => setNomenclatorValue(e.target.value)}
                fullWidth
              />
            )}

            {selectedNomenclator !== "tvas" ||
              (selectedNomenclator !== "sgrs" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nomenclatorEnabled}
                      onChange={(e) => setNomenclatorEnabled(e.target.checked)}
                    />
                  }
                  label="Activat"
                />
              ))}

            {/* {selectedNomenclator !== "ums" ||
              (selectedNomenclator !== "categories" && (
                <TextField
                  type="text"
                  placeholder="Valoare"
                  value={nomenclatorValue}
                  onChange={(e) => setNomenclatorValue(e.target.value)}
                />
              ))} */}

            {/* {selectedNomenclator === "sgrs" && (
              <>
                <InputLabel id="demo-simple-select-label">TVA </InputLabel>
                <Select
                  label="TVA"
                  value={tva}
                  onChange={(e) => {
                    setTva(e.target.value);
                  }}
                >
                  {licenseData?.nomenclatoare?.tvas?.map((item) => (
                    <MenuItem key={item} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )} */}
            {selectedNomenclator === "sgrs" && (
              <>
                <InputLabel id="demo-simple-select-label">TVA</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={tva || ""} // Make sure this holds the selected label, not a value
                  onChange={(e) => setTva(e.target.id)} // This will set the label directly
                  fullWidth // Ensure the dropdown is properly displayed in width
                >
                  {licenseData?.nomenclatoare?.tvas?.map((item) => (
                    <MenuItem key={item.label} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {/* {selectedNomenclator === "sgrs" && (
  <>
    <InputLabel id="demo-simple-select-label">TVA</InputLabel>
    <Select
      label="TVA"
      value={tva}
      onChange={(e) => {
        setTva(e.target.value);
      }}
      fullWidth
    >
      {licenseData?.nomenclatoare?.tvas?.map((item) => (
        <MenuItem key={item.id} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  </>
)} */}

            {selectedNomenclator === "tvas" && (
              <>
                <TextField
                  type="number"
                  placeholder="Index AMEF"
                  value={indexAmef}
                  onChange={(e) => setIndexAmef(e.target.value)}
                  fullWidth
                />
                <TextField
                  type="number"
                  placeholder="Valoare TVA"
                  value={nomenclatorValue} // This captures the value property
                  onChange={(e) => setNomenclatorValue(e.target.value)}
                  fullWidth
                />
              </>
            )}
            <br />

            <Button
              variant="contained"
              color="success"
              onClick={() =>
                addNomenclator(`nomenclatoare.${selectedNomenclator}`)
              }
            >
              Adaugă Nomenclator
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editCategoryModalOpen}
        onClose={() => setEditCategoryModalOpen(false)}
        aria-labelledby="edit-category-modal-title"
        aria-describedby="edit-category-modal-description"
      >
        <Box sx={styleInv}>
          <h2>Editează Categoria</h2>
          <TextField
            margin="dense"
            label="Category Label"
            type="text"
            fullWidth
            value={currentCategory.label || ""}
            onChange={(e) =>
              setCurrentCategory({ ...currentCategory, label: e.target.value })
            }
          />
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setEditCategoryModalOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                editCategory(currentCategory);
                setEditCategoryModalOpen(false);
              }}
              color="primary"
            >
              Salvează
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LicentaView;

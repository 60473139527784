import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const generateExcelFile = (excelObj, materiale) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Raport Produse");

  // Define header rows with custom styles
  const headerRows = [
    ["LUKA FISCAL SOLUTIONS S.R.L."],
    [
      "CIF: RO38501025 | Strada 23 August, Nr.2, Parter, Bloc R4, Scara 1, Ap. 1 | Tel:0311 307 631",
    ],
    [],
    ["Raport produse vandute"],
    [`Perioada: ${excelObj.startDate} - ${excelObj.endDate}`],
    [],
    [
      "Produs",
      "Categorie",
      "Cod Produs",
      "Numar Bon",
      "Cantitate",
      "U/M",
      "Pret unitar (cu TVA)",
      "Cota TVA",
      "Total",
    ],
  ];

  // Add header rows to the worksheet with styles
  headerRows.forEach((row, index) => {
    const excelRow = worksheet.addRow(row);
    if (index < 2) {
      // Apply styles to the first 6 rows (header)
      excelRow.eachCell((cell) => {
        cell.font = {
          name: "Arial",
          size: 16,
          bold: true,
          color: { argb: "8295a2" }, // Red color
        };
      });
    }
  });

  // Add a horizontal line separator (this will be a formatted empty row)
  worksheet.addRow([]).height = 5; // Adding a blank row as a separator

  // Create rows for each product dynamically
  const productRows = materiale.map((item) => {
    return [
      item.name,
      item.category,
      item.cod,
      item.bonNr,
      item.count,
      item.um,
      item.price,
      item.tva,
      item.total,
    ];
  });

  // Add product rows to the worksheet
  productRows.forEach((row) => {
    worksheet.addRow(row);
  });

  // Calculate total of all products
  const totalSum = productRows.reduce((acc, curr) => acc + curr[8], 0);

  // Add total row at the end
  worksheet.addRow([]);
  worksheet.addRow([
    "Total",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    totalSum.toLocaleString(),
  ]);

  // Set column widths
  worksheet.columns = [
    { width: 100 },
    { width: 20 },
    { width: 15 },
    { width: 15 },
    { width: 10 },
    { width: 10 },
    { width: 20 },
    { width: 10 },
    { width: 15 },
  ];

  // Generate the Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Vanzari_Produse_${excelObj.current}.xlsx`);
  });
};
